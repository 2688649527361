<template>
  <div class="foot">
    <span id="copy"> &copy;{{ currentYear }} Lipsen.ltd 版权所有</span>
    <div class="beian-info">
      <img src="../assets/batb.png" />
      <p>琼公网安备 46010802000836号</p>
      <a href="https://beian.miit.gov.cn/#/Integrated/index"
        >琼ICP备2021003942号</a
      >
    </div>
  </div>
</template>

<script setup>
const date = new Date();
let currentYear = date.getFullYear();
</script>

<style lang="scss">
.foot {
  position: absolute;
  width: 90%;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 12px;
  #copy {
    display: block;
    margin-bottom: 4px;
    color: #fff;
    font-size: 14px;
  }
}
.beian-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  vertical-align: middle;
  margin: 5px;
  color: #fff;
  img {
    height: 15px;
    width: 15px;
    display: inline-block;
    vertical-align: middle;
    margin: 0px 3px 0px 0px;
  }
  p {
    vertical-align: middle;
    margin: 0px 10px 0px 0px;
  }
  a {
    text-decoration: none;
    color: #fff;
  }
}
</style>
