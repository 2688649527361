import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    meta: { title: "Wellcome to Lipsen.ltd!" },
    component: HomeView,
  },
  {
    path: "/nav",
    name: "nav",
    meta: { title: "导航" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "nav" */ "../views/NavView.vue"),
  },
  {
    path: "/exam",
    name: "exam",
    meta: { title: "三基考试二维码查询" },
    component: () =>
      import(/* webpackChunkName: "calendar" */ "../views/ExamRouter.vue"),
  },
  {
    path: "/calendar",
    name: "calendar",
    meta: { title: "肛肠科排班表-日历模式" },
    component: () =>
      import(/* webpackChunkName: "calendar" */ "../views/CalendarView.vue"),
  },
  {
    path: "/calendar/table",
    name: "table",
    meta: { title: "肛肠科排班表-表格模式" },
    component: () =>
      import(/* webpackChunkName: "calendar" */ "../views/DutyTable.vue"),
  },

  {
    path: "/mini_wiki/start",
    name: "mini_wiki_start",
    meta: { title: "请选择你的角色" },
    component: () =>
      import(
        /* webpackChunkName: "calendar" */ "../views/MiniWiki/WikiStart.vue"
      ),
  },
  {
    path: "/mini_wiki/edit",
    name: "mini_wiki_edit",
    meta: { title: "编辑" },
    component: () =>
      import(
        /* webpackChunkName: "calendar" */ "../views/MiniWiki/WikiEdit.vue"
      ),
  },
  {
    path: "/mini_wiki/inspect",
    name: "mini_wiki_inspect",
    meta: { title: "审核" },
    component: () =>
      import(
        /* webpackChunkName: "calendar" */ "../views/MiniWiki/WikiInspect.vue"
      ),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    meta: { title: "页面未找到" },
    component: () =>
      import(/* webpackChunkName: "calendar" */ "../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
