<template>
  <div id="app">
    <div class="app-content">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss">
@import "./assets/fonts/fonts.css";
#app {
  display: flex;
  flex-direction: column;
  font-family: "PingFang SC", "Hiragino Sans GB", "STHeiti", "Microsoft YaHei",
    "WenQuanYi Micro Hei", sans-serif;
}
.app-content {
  flex: 1;
}
</style>
