import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import axios from "axios";
// import vuetify from "@plugins/vuetify";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

const vuetify = createVuetify({
  components,
  directives,
});
function getCookie(name) {
  var r = document.cookie.match("\\b" + name + "=([^;]*)\\b");
  return r ? r[1] : undefined;
}
axios.interceptors.request.use((config) => {
  config.headers["Content-Type"] = "application/json;charset=utf-8";
  config.headers["X-Requested-With"] = "XMLHttpRequest";
  config.headers["X-CSRFToken"] = getCookie("csrf_token");
  return config;
});

const app = createApp(App);
app.mixin({
  beforeMount() {
    const csrfToken = document.cookie.replace(
      /(?:(?:^|.*;\s*)csrf_token\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    axios.defaults.headers.common["X-CSRFToken"] = csrfToken;
  },
});
app.use(store).use(router).use(ElementPlus).use(vuetify);

// axios
//   .get("/api/get_csrf_token")
//   .then((response) => {
//     const csrfToken = response.data.csrf_token;

//     const app = createApp(App);
//     app.provide("$csrfToken", csrfToken); // 提供 CSRF 令牌
//   })
//   .catch((error) => {
//     console.error("获取CSRF令牌时出错：", error);
//   });
app.mount("#app");
